import React, { useEffect, useState } from 'react'; 
import HappyFaceSVG from '../../assets/happy_face.svg';
import './home.css';

export default function Home() { 
    useEffect(() => {
        document.title = "MOHAMED BAKOUSH";
    });

    const [navLinkHover1, setNavLinkHover1] = useState(false);
    const [navLinkHover2, setNavLinkHover2] = useState(false);
    const [navLinkHover3, setNavLinkHover3] = useState(false);    
    
    const { width } = useWindowDimensions();
    
    const handleMouseEnter = (linkNumber : Number) => {
        switch (linkNumber) {
            case 1:
                setNavLinkHover1(true);
                break;
            case 2:
                setNavLinkHover2(true);
                break;
            case 3:
                setNavLinkHover3(true);
                break;
            default:
                break;
        }
    };
    
    const handleMouseLeave = (linkNumber : Number) => {
        switch (linkNumber) {
            case 1:
                setNavLinkHover1(false);
                break;
            case 2:
                setNavLinkHover2(false);
                break;
            case 3:
                setNavLinkHover3(false);
                break;
            default:
                break;
        }
    };
    
    const linkUrls = [
        [
            "TWITTER", 
            "GITHUB", 
            "LINKEDIN"
        ],
        [
            "https://twitter.com/pakonsy",
            "https://github.com/MohamedBakoush",
            "https://www.linkedin.com/in/mohamed-bakoush/"
        ]
    ];
    
    const navLinkHover = [navLinkHover1, navLinkHover2, navLinkHover3];
    
    const renderLinks = (isMobile : Boolean) => (
        <section className={isMobile ? "home-wrapper-links-container-2" : "home-wrapper-links-container"}>
            {linkUrls[0].map((linkText, index) => (
                <>
                    <a
                        onMouseEnter={() => handleMouseEnter(index + 1)}
                        onMouseLeave={() => handleMouseLeave(index + 1)}
                        className={
                            navLinkHover[index]
                                ? "links-container-link-idel"
                                : (!navLinkHover1 && !navLinkHover2 && !navLinkHover3)
                                    ? "links-container-link-hover"
                                    : "links-container-link-not-hover"
                        }
                        href={linkUrls[1][index]}
                        rel="noreferrer"
                        target="_blank">
                        {isMobile ? linkText : linkText.slice(0, 4)}
                    </a>
                    {index < 2 && <div className="links-container-line" />}
                </>
            ))}
        </section>
    );
    
    const renderInfo = (title: String, description: String) => {
        return (
            <section className="home-wrapper-content">
                <p className="home-title">{title}</p>
                <p className="home-description">{description}</p>
            </section>
        );
    }

    return (
        <article className="home-article">
            <section className="home-wrapper">
                <section className="home-wrapper-section">  
                    {width >= 1024 && renderLinks(false)}
                </section>  
                {width < 1024 && renderLinks(true)}
            </section>    
        </article>
    )
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());
    React.useEffect(() => {
        function handleResize() {
        setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowDimensions;
} 