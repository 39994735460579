import React, { useEffect } from 'react';    
import { Link } from 'react-router-dom'; 
import './error.css';

function Error() {  
    useEffect(() => {
        document.title = "MOHAMED BAKOUSH | 404: This page could not be found";
    });  
    return (
        <article className="error-article">  
            <p className="error-title">
                404 / PAGE NOT FOUND
            </p>
            <p  className="error-text">
                Sorry about that - we may have done something wrong, or you may have done something weird. Let's all try again.
            </p>   
            <Link to="/" className="error-button">
                Go back to home
            </Link>  
        </article>
    )
}

export default Error;