import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom'; 
import Home from './home/home';
import Error from './error/error';
import './loading/loading.css';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default function App() {
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    const handleLoad = () => {
      setIsLoading(false);
    };

    if (document.readyState === 'complete') {
      handleLoad();
    } else {
      window.addEventListener('load', handleLoad);
    }

    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  if (isLoading) {
    return (
      <p className="loading-indicator">
        <span className="loading-indicator__item loading-indicator__item--1">L</span>
        <span className="loading-indicator__item loading-indicator__item--2">o</span>
        <span className="loading-indicator__item loading-indicator__item--3">a</span>
        <span className="loading-indicator__item loading-indicator__item--4">d</span>
        <span className="loading-indicator__item loading-indicator__item--5">i</span>
        <span className="loading-indicator__item loading-indicator__item--6">n</span>
        <span className="loading-indicator__item loading-indicator__item--7">g</span>
      </p>
    );
  }

  return (
    <BrowserRouter> 
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />   
        <Route path="*" element={<Error />} />
      </Routes> 
    </BrowserRouter>
  );
}